import { useState, useEffect, useContext, useMemo } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* contexts */
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import { retornaDataHora } from "../../../utils/retornaDataFormatada";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import DataList from "../../form/DataList";
import SelectEmpresa from "../../form/SelectEmpresa";

import formStyles from "../../form/Form.module.css";

/* apis */
import * as EmbalagemAPI from "../../../api/embalagem";
import * as DataListAPI from "../../../api/dataList";
import * as TabelaAPI from "../../../api/tabela";
import * as AlmoxarifadoAPI from "../../../api/almoxarifado";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsEmbalagem }) {
    const [dados, setDados] = useState({});
    const [itensEmbalagens, setItensEmbalagens] = useState([]); //itensInventario da grid

    const [Empresa, setEmpresa] = useState({});
    const [Embalagem, setEmbalagem] = useState({});

    const [preview, setPreview] = useState([]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const { parametro } = useContext(Context);

    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul") || 2;
    const li = urlParams.get("li") || 32;

    /* Empresa usa tabela comum ou propria */
    let empresaId = 1;
    if (parametro.tabelasComuns === false) {
        empresaId = localStorage.getItem("empresaId");
    }

    //set datalist
    const [NCM, setNCM] = useState(formData?.NCM);
    const [OptionsNCM, setOptionsNCM] = useState([]);
    const [Fabricante, setFabricante] = useState(formData?.Fabricante || {});
    const [produtoBens, setBem] = useState(formData?.produtoBens || {});
    const [produtoImagems, setProdutoImagems] = useState([]);
    const [OptionsFabricante, setOptionsFabricante] = useState([]);
    const [produtoEmbalagens, setProdutoEmbalagem] = useState([]);

    const [OptionsAlmoxarifados, setOptionsAlmoxarifado] = useState([]);

    // Estado para rastrear a aba ativa
    const [abaAtiva, setAbaAtiva] = useState(0);
    const [gridAtiva, setGridAtiva] = useState("produto");

    /* identifica a aba atual */
    const handleSelect = (index) => {
        setAbaAtiva(index);

        // Definir qual grid será atualizada com base na aba ativa
        switch (index) {
            case 0:
                setGridAtiva("produto");
                break;
            case 1:
                setGridAtiva("industria");
                break;
            case 2:
                setGridAtiva("embalagens");
                break;
            case 3:
                setGridAtiva("caracteristicas");
                break;
            case 4:
                setGridAtiva("bens");
                break;
            case 5:
                setGridAtiva("imagens");
                break;
            default:
                setGridAtiva("produto");
        }
    };

    //abas
    const [tabEnabled, setTabEnabled] = useState({
        produto: true,
        industria: true,
        embalagens: true,
        caracteristicas: true,
        bens: true,
        imagens: true,
    });

    //carrega informações para cadastro inicial
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
    }
    if (!dados.tipo) {
        dados.tipo = "Material";
    }

    //set enums
    const [EnumTipo, setTipo] = useState(formData?.OptionsTipo || {});
    const [EnumBemTipo, setBemTipo] = useState(formData?.OptionsBemTipo || {});

    //carregar ENUMS
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Produtos") {
            field === "tipo" && setTipo(result ? result : []);
        } else if (table === "produtoBens") {
            field === "tipo" && setBemTipo(result ? result : []);
        }
    };

    //api
    const consultaTabelaById = async (empresaId, tabela, id) => {
        const result = await TabelaAPI.tabelaById(empresaId, tabela, id);

        if (tabela === "Empresas") {
            setEmpresa(result);
        } else if (tabela === "Embalagem") {
            setEmbalagem(result);
        }
        return result;
    };
    const consultaAlmoxarifados = async (empresaId = dados.empresaId) => {
        const result = await AlmoxarifadoAPI.almoxarifados(empresaId);
        setOptionsAlmoxarifado(result);
        return result;
    };
    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    //rotina para criar objetos de tabelas filhas
    const [novaEmbalagem, setNovaEmbalagem] = useState({
        produtoId: dados.id,
        empresaId: dados.empresaId,
        Empresa: Empresa,
        embalagemId: "",
        Embalagem: Embalagem,
        uuid: "",
        codigoBarras: "",
        ativo: true,
        funcao: "adicionar",
    });

    useEffect(() => {
        setNovaEmbalagem((prevEmbalagem) => ({
            ...prevEmbalagem,
            produtoId: dados.id,
        }));
    }, [dados.id]);

    useEffect(() => {
        setDados(formData || {});
        setFabricante(formData?.Fabricante || {});

        setProdutoEmbalagem(formData?.produtoEmbalagens || []);
        setItensEmbalagens(formData?.produtoEmbalagens || []); //atualiza a grid de embalagens

        setNCM(formData.NCM || {});

        setBem(formData?.produtoBens || {});

        setProdutoImagems(formData?.produtoImagems || []);
        setTipo(formData?.OptionsTipo);
        setBemTipo(formData?.OptionsBemTipo);

        setTabEnabled({
            produto: true,
            industria: true,
            embalagens: true,
            caracteristicas: true,
            bens: dados.tipo === "Imobilizado" || dados.tipo === "Material" ? true : false,
            imagens: true,
        });

        //enums
        consultaEnum("Produtos", "tipo");
        consultaEnum("produtoBens", "tipo");
        consultaAlmoxarifados(dados.empresaId);
        consultaTabelaById(null, "Empresa", dados.empresaId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    //atualiza tabelas filhas
    useEffect(() => {
        setDados((prev) => {
            return {
                ...prev,
                produtoBens: produtoBens,
                produtoEmbalagens: produtoEmbalagens,
            };
        });
    }, [produtoBens, produtoEmbalagens]);

    async function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setDados({ ...dados, images: [...e.target.files] });
    }
    async function handleChange(e) {
        const camposMonetarios = [
            "comissao",
            "pesoBruto",
            "pesoLiquido",
            "altura",
            "largura",
            "comprimento",
            "volume",
            "estoqueMinimo",
            "producaoMinima",
            "pesoMolecular",
            "toleranciaMinima",
            "toleranciaMaxima",
        ];
        const camposBooleanos = [
            "ativo",
            "compra",
            "venda",
            "locavel",
            "embalagem",
            "laminado",
            "sanfonado",
            "separacao",
            "refugo",
            "lavagem",
            "producao",
            "extrusao",
            "finalizaExtrusora",
            "recuperadora",
            "corrugadora",
            "impressora",
            "corteSolda",
        ];
        if (e.target.name === "tipo") {
            setDados({ ...dados, [e.target.name]: e.target.value });

            if (e.target.value === "Imobilizado" || e.target.value === "Material") {
                setTabEnabled({
                    ...tabEnabled,
                    bens: true,
                });
            } else {
                setTabEnabled({
                    ...tabEnabled,
                    bens: false,
                });
            }
        } else if (camposBooleanos.includes(e.target.name)) {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name.startsWith("NCM")) {
            setNCM({
                ...NCM,
                [e.target.name.split(".")[1]]: e.target.value,
            });
            if (e.target.name.startsWith("NCM.descricao")) {
                //carrega o datalist
                if (e.target.value?.length > 3) {
                    let result = await consultaDatalist(token, empresaId, "NCM", "id", "descricao", "", e.target.value);
                    setOptionsNCM(result);
                }

                setDados({
                    ...dados,
                    ncmId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                });

                if (document.querySelector(`datalist option[value="${e.target.value}"]`)?.value) {
                    // const ncmDescricao = document.querySelector(
                    //     `datalist option[value="${e.target.value}"]`
                    // )?.value;
                    const arr = document.querySelector(`datalist option[value="${e.target.value}"]`)?.value?.split(" ");

                    setNCM({ ncm: arr[0] });
                }
            }
        } else if (e.target.name.startsWith("Fabricante")) {
            setFabricante({
                ...Fabricante,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            if (e.target.value?.length > 2) {
                let result = await consultaDatalist(
                    token,
                    dados.empresaId,
                    "Pessoa",
                    "id",
                    "descricao",
                    "fabricante",
                    e.target.value
                );
                setOptionsFabricante(result);
            }

            setDados({
                ...dados,
                fabricanteId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
            });
        } else if (camposMonetarios.includes(e.target.name)) {
            const valorFormatado = formatarNumeroDecimal(e.target.value);

            // Atualiza o estado do campo modificado
            const novosDados = { ...dados, [e.target.name]: valorFormatado };

            // Calcula o volume se altura, largura ou comprimento forem alterados
            if (["altura", "largura", "comprimento"].includes(e.target.name)) {
                const altura = parseFloat(novosDados.altura) || 0;
                const largura = parseFloat(novosDados.largura) || 0;
                const comprimento = parseFloat(novosDados.comprimento) || 0;

                // Calcula o volume em cm³ e converte para m³
                const volumeCm3 = altura * largura * comprimento;
                const volumeM3 = volumeCm3 / 1000000;

                novosDados.volume = volumeM3;
            }
            setDados(novosDados);
            //setDados({ ...dados, [e.target.name]: valorFormatado });
        } else if (e.target.name.startsWith("produtoBens.")) {
            //para checkbox
            let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            setBem({
                ...produtoBens,
                [e.target.name.split(".")[1]]: valor,
            });
        } else if (e.target.name.startsWith("novaEmbalagem.")) {
            // Verifica se é um checkbox e define o valor correspondente
            let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;

            setNovaEmbalagem({
                ...novaEmbalagem,
                [e.target.name.split(".")[1]]: valor,
                empresa:
                    document.getElementById("novaEmbalagem.empresaId").options[
                        document.getElementById("novaEmbalagem.empresaId").selectedIndex
                    ].text,
                embalagem:
                    document.getElementById("novaEmbalagem.embalagemId").options[
                        document.getElementById("novaEmbalagem.embalagemId").selectedIndex
                    ].text,
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        if (e.target.name === "descricao") {
            //verifica duplicidade
            if (e.target.name === "descricao" && btnText === "Cadastrar") {
                //verifica se o centroCusto existe na base de dados
                const duplicidade = await TabelaAPI.duplicidadeDescricao(
                    document.getElementById("empresaId").value,
                    "Produto",
                    e.target.value
                );

                if (duplicidade?.uuid) {
                    setFlashMessage("Registro em duplicidade!", "warning");
                    return navigate(`/Produtos/CreateOrEdit`, {
                        uuid: duplicidade.uuid,
                    });
                }
            }
        }
    }
    async function handleOnFocus(e) {
        if (e.target.name === "produtoBens.descricao") {
            setBem({
                ...produtoBens,
                descricao: dados.descricao,
            });
        }
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }

        //validações
        if (!dados.ncmId) {
            setFlashMessage("NCM é um campo obrigatório!", "error");
            return;
        }

        handleSubmit(dados);
    };

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    /* modo tema */
    const theme = tableThemeCustom();

    const columns = useMemo(
        () => [
            {
                id: "embalagem",
                header: "Embalagens do produto",
                columns: [
                    { accessorKey: "Empresa.nomeFantasia", header: "Empresa" },
                    {
                        accessorKey: "Embalagem.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "codigoBarras",
                        header: "Código de Barras",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                    },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { label: "Todos", value: "" },
                            { label: "Ativo", value: 1 },
                            { label: "Inativo", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ row }) => {
                            return row.original.ativo ? "Ativo" : "Inativo";
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    const handleEmbalagem = async () => {
        // Função auxiliar para resetar novaEmbalagem
        const resetNovaEmbalagem = () => ({
            produtoId: dados.id,
            empresaId: novaEmbalagem.empresaId,
            embalagemId: novaEmbalagem.embalagemId,
            uuid: "",
            codigoBarras: "",
            ativo: true,
            funcao: "adicionar",
        });

        // Verifica e define campos não preenchidos
        novaEmbalagem.produtoId = novaEmbalagem.produtoId || parseInt(dados.id);
        novaEmbalagem.embalagemId =
            novaEmbalagem.embalagemId || parseInt(document.getElementById("novaEmbalagem.embalagemId").value);
        novaEmbalagem.empresaId = novaEmbalagem.empresaId || parseInt(document.getElementById("novaEmbalagem.empresaId").value);

        if (dados.id) {
            const result = await CreateOrEditEmbalagem(novaEmbalagem);

            if (result.status === 202) {
                setFlashMessage("Embalagem já cadastrada!", "info");
            } else {
                setFlashMessage(result.message, result.type);

                if (result.type === "success") {
                    setProdutoEmbalagem(result.resultado);
                }

                setNovaEmbalagem(resetNovaEmbalagem());
            }

            fetchData("embalagens");
        } else {
            const empresa = await consultaTabelaById(null, "Empresa", novaEmbalagem.empresaId);
            const embalagem = await consultaTabelaById(novaEmbalagem.empresaId, "Embalagem", novaEmbalagem.embalagemId);

            if (novaEmbalagem.funcao === "editar") {
                if (novaEmbalagem.uuid) {
                    await CreateOrEditEmbalagem(novaEmbalagem);
                } else {
                    setItensEmbalagens((prevItens) => {
                        const novosItens = [...prevItens];
                        novosItens[novaEmbalagem.index] = { ...prevItens[novaEmbalagem.index], ...novaEmbalagem };
                        return novosItens;
                    });
                    setRowCount(itensEmbalagens.length);
                }
            } else if (novaEmbalagem.funcao === "deletar") {
                if (novaEmbalagem.uuid) {
                    try {
                        const response = await EmbalagemAPI.deletarEmbalagem(novaEmbalagem);
                        setFlashMessage(response.message, response.type);
                    } catch (error) {
                        console.error(error);
                        setFlashMessage(error.response.message, error.data.type);
                    }
                } else {
                    setItensEmbalagens((prevItens) => {
                        const novosItens = [...prevItens];
                        novosItens.splice(novaEmbalagem.index, 1);
                        return novosItens;
                    });
                }
            } else {
                if (dados.id) {
                    novaEmbalagem.produtoId = dados.id;

                    const result = await EmbalagemAPI.duplicidadeCodigobarras(
                        novaEmbalagem.empresaId,
                        novaEmbalagem.codigoBarras
                    );

                    if (result && novaEmbalagem.funcao === "adicionar") {
                        setFlashMessage("Código de barras em duplicidade!", "warning");
                        handleEditar(result);
                        return;
                    }

                    await CreateOrEditEmbalagem(novaEmbalagem);
                } else {
                    const codigoBarrasExiste = itensEmbalagens.some(
                        (item) => item.codigoBarras === novaEmbalagem.codigoBarras && item.empresaId === novaEmbalagem.empresaId
                    );

                    if (codigoBarrasExiste) {
                        setFlashMessage("Código de barras já cadastrado para o produto!", "warning");
                        return;
                    }

                    const result = await EmbalagemAPI.duplicidadeCodigobarras(
                        novaEmbalagem.empresaId,
                        novaEmbalagem.codigoBarras
                    );

                    if (result && novaEmbalagem.funcao === "adicionar") {
                        setFlashMessage("Código de barras em duplicidade!", "warning");
                        return;
                    }

                    const novoItemArray = [
                        ...itensEmbalagens,
                        {
                            uuid: novaEmbalagem.uuid,
                            produtoId: novaEmbalagem.produtoId || dados.Id,
                            empresaId: novaEmbalagem.empresaId,
                            embalagemId: novaEmbalagem.embalagemId,
                            codigoBarras: novaEmbalagem.codigoBarras,
                            ativo: novaEmbalagem.ativo,
                            Empresa: empresa,
                            Embalagem: embalagem,
                        },
                    ];

                    setItensEmbalagens(novoItemArray);
                    setRowCount(novoItemArray.length);
                    setProdutoEmbalagem(novoItemArray);
                    setDados((prev) => ({
                        ...prev,
                        produtoEmbalagens: novoItemArray,
                    }));
                }
            }

            setNovaEmbalagem(resetNovaEmbalagem());
        }
    };

    const CreateOrEditEmbalagem = async (novaEmbalagem) => {
        try {
            const result = await EmbalagemAPI.managerProdutoEmbalagem(novaEmbalagem);
            setFlashMessage(result.message, result.type);
            fetchData("embalagens");
            return result;
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };

    const handleEditar = async (originalRow, row) => {
        // Define os dados para o novo produto
        const novaEmbalagem = {
            uuid: originalRow.uuid,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            produtoId: originalRow.produtoId,
            embalagemId: originalRow.embalagemId,
            codigoBarras: originalRow.codigoBarras,
            descricao: originalRow.descricao,

            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovaEmbalagem(novaEmbalagem);

        return;
    };
    const handleDelete = (originalRow, row) => {
        const result = {
            uuid: originalRow.uuid,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            produtoId: originalRow.produtoId,
            embalagemId: originalRow.embalagemId,
            codigoBarras: originalRow.codigoBarras,
            descricao: originalRow.descricao,

            ativo: originalRow.ativo,
            funcao: "deletar",
            index: row,
        };

        // Atualize o estado
        setNovaEmbalagem(result);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData(gridAtiva);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, gridAtiva]);

    const fetchData = async (grid) => {
        if (!itensEmbalagens.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        if (dados.id) {
            if (grid === "embalagens") {
                try {
                    EmbalagemAPI.listarEmbalagens(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.id ?? ""
                    ).then((response) => {
                        setItensEmbalagens(response.data.data);
                        setRowCount(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    return (
        <Tabs selectedIndex={abaAtiva} onSelect={handleSelect}>
            <form onSubmit={submit}>
                <h1>Gestão de Produtos</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.produto} title={"Infomações genericas do produto"}>
                        Produto
                    </Tab>
                    <Tab
                        disabled={!tabEnabled.industria}
                        title={"Informações relevantes no processo de industrialização do produto"}
                    >
                        Indústria
                    </Tab>
                    <Tab disabled={!tabEnabled.embalagens} title={"Embalagens do produto"}>
                        Embalagens
                    </Tab>
                    <Tab disabled={!tabEnabled.caracteristicas} title={"Caracteristicas do produto"}>
                        Caracteristicas
                    </Tab>
                    <Tab disabled={!tabEnabled.bens} title={"Informações do bem móvel ou imóvel"}>
                        Bens
                    </Tab>
                    <Tab disabled={!tabEnabled.imagens} title={"Imagens do produto"}>
                        Imagens
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações principais do Produto</h4>
                    </div>
                    <div>
                        <SelectEmpresa
                            name="empresaId"
                            text="Empresa"
                            options={OptionsEmpresa}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.empresaId}
                            isEnum={false}
                            notOptNull={true}
                        />
                        <Input
                            text="Data Cadastro"
                            type="datetime-local"
                            name="createdAt"
                            placeholder="última atualização"
                            title="Data de inserção do cadastro no sistema"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.createdAt && retornaDataHora(dados.createdAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Input
                            text="Data Atualização"
                            type="datetime-local"
                            name="updatedAt"
                            placeholder="última atualização"
                            title="Data da última atualização dos dados"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.updatedAt && retornaDataHora(dados.updatedAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Checkbox
                            title="Ativa ou Inativa o produto"
                            text="Ativo"
                            name="ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.ativo}
                            checked={dados.ativo === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de compras"
                            text="Compra"
                            name="compra"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.compra}
                            checked={dados.compra === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de vendas"
                            text="Venda"
                            name="venda"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.venda}
                            checked={dados.venda === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto pode ser locado"
                            text="Locavel"
                            name="locavel"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.locavel}
                            checked={dados.locavel === true ? "checked" : ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="Código"
                            type="text"
                            name="codigo"
                            placeholder="Código"
                            title="O sistema vai gerar um código para o produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigo || ""} //posso colocar para gerar um código
                            readonly={true}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Descrição"
                            type="text"
                            name="descricao"
                            placeholder="Descrição"
                            title="digite a descrição do produto"
                            inputWidth="350px"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.descricao || ""}
                            readonly={false}
                        />
                        <Select
                            name="tipo"
                            text="Tipo"
                            title="Determina o tipo do produto"
                            options={EnumTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.tipo}
                            isEnum={true}
                        />
                    </div>
                    <div>
                        <Input
                            text="referência"
                            type="text"
                            name="referencia"
                            placeholder="Código de referência"
                            title="Digite o código de referência do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.referencia || ""}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Código Fabricante"
                            type="text"
                            name="codigoFabricante"
                            placeholder="Código Fabricante"
                            title="Digite o código do fabricante do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigoFabricante || ""}
                            inputWidth={"150px"}
                        />
                        <DataList
                            text="Fabricante"
                            title={"Vincula o Fabricante ao produto"}
                            name="Fabricante.descricao"
                            inputWidth="350px"
                            filteredOptions={OptionsFabricante}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={Fabricante.descricao || ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="NCM"
                            type="text"
                            name="NCM.ncm"
                            placeholder="NCM"
                            inputWidth="150px"
                            title="Código do ncm do produto"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.ncm}
                            readonly={true}
                        />
                        <DataList
                            text="NCM Descrição"
                            title={"Vincula o NCM ao produto"}
                            name="NCM.descricao"
                            placeholder={"Digite mais de 3 caracteres para pesquisar"}
                            inputWidth="510px"
                            filteredOptions={OptionsNCM}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.descricao}
                            required={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações relevantes no processo de industrialização do produto</h4>
                    </div>

                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <InputNumber
                                text="Peso Molecular"
                                type="text"
                                name="pesoMolecular"
                                placeholder="peso molecular"
                                inputWidth="160px"
                                title="digite o peso molecular do produto"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.pesoMolecular ? formatarNumero(dados.pesoMolecular) : ""}
                            />
                            <InputNumber
                                text="Tolerância mínima"
                                type="text"
                                name="toleranciaMinima"
                                placeholder="peso em gramas"
                                inputWidth="160px"
                                title="digite a tolerância mínima em razão do peso molecular"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.toleranciaMinima ? formatarNumero(dados.toleranciaMinima) : ""}
                            />
                            <InputNumber
                                text="Tolerância máxima"
                                type="text"
                                name="toleranciaMaxima"
                                placeholder="Tolerência máxima"
                                inputWidth="160px"
                                title="digite a tolerância máxima em razão do peso molecular"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.toleranciaMaxima ? formatarNumero(dados.toleranciaMaxima) : ""}
                            />
                            <InputNumber
                                text="Produção Mínima"
                                type="text"
                                name="producaoMinima"
                                placeholder="peso em gramas"
                                inputWidth="160px"
                                title="valor mínimo de produção do produto"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.producaoMinima ? formatarNumero(dados.producaoMinima) : ""}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <Checkbox
                                title="Informa que é uma embalagem"
                                text="Embalagem"
                                name="embalagem"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.embalagem}
                                checked={dados.embalagem === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto é laminado"
                                text="Laminado"
                                name="laminado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.laminado}
                                checked={dados.laminado === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto é sanfonado"
                                text="Sanfonado"
                                name="sanfonado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.sanfonado}
                                checked={dados.sanfonado === true ? "checked" : ""}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de separação"
                                text="Separação"
                                name="separacao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.separacao}
                                checked={dados.separacao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto gera refugo"
                                text="Refugo"
                                name="refugo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.refugo}
                                checked={dados.refugo === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de lavagem"
                                text="Lavagem"
                                name="lavagem"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.lavagem}
                                checked={dados.lavagem === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de produção"
                                text="Produção"
                                name="producao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.producao}
                                checked={dados.producao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de extrusão"
                                text="Extrusão"
                                name="extrusao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.extrusao}
                                checked={dados.extrusao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o prcesso de produção do produto finaliza na extrusão"
                                text="Finaliza Extrusão"
                                name="finalizaExtrusora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.finalizaExtrusora}
                                checked={dados.finalizaExtrusora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza da Recuperadora"
                                text="Recuperadora"
                                name="recuperadora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.recuperadora}
                                checked={dados.recuperadora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza da corrugadora"
                                text="Corrugadora"
                                name="corrugadora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.corrugadora}
                                checked={dados.corrugadora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina da impressora"
                                text="Impressora"
                                name="impressora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.impressora}
                                checked={dados.impressora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina da corte e solda"
                                text="Corte Solda"
                                name="corteSolda"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.corteSolda}
                                checked={dados.corteSolda === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Embalagens do produto</h4>
                    </div>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <SelectEmpresa
                                    name="novaEmbalagem.empresaId"
                                    text="Empresa"
                                    options={OptionsEmpresa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.empresaId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Select
                                    name="novaEmbalagem.embalagemId"
                                    text="Embalagem"
                                    options={OptionsEmbalagem}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.embalagemId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Input
                                    text="Código de Barras"
                                    type="text"
                                    name="novaEmbalagem.codigoBarras"
                                    placeholder="Código de Barras"
                                    title="digite o código de barras do produto"
                                    inputWidth="200px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.codigoBarras || ""}
                                    readonly={false}
                                />
                                <Checkbox
                                    title="Ativo quando marcado"
                                    text="Ativo"
                                    name="novaEmbalagem.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.ativo}
                                    checked={novaEmbalagem.ativo === true ? "checked" : ""}
                                />{" "}
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovaEmbalagem({
                                            uuid: "",
                                            empresaId: dados.empresaId,
                                            produtoId: dados.id,
                                            embalagemId: "",
                                            codigoBarras: "",
                                            ativo: true,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaEmbalagem.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleEmbalagem(novaEmbalagem)}
                                    disabled={novaEmbalagem.codigoBarras?.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaEmbalagem.funcao}
                                </Button>
                            </div>
                        </div>
                        <ThemeProvider theme={theme}>
                            <MaterialReactTable
                                displayColumnDefOptions={{
                                    "mrt-row-actions": {
                                        muiTableHeadCellProps: {
                                            align: "center",
                                        },
                                        size: 100,
                                    },
                                    "mrt-row-numbers": {
                                        enableColumnOrdering: true, //turn on some features that are usually off
                                        enableResizing: true,
                                        muiTableHeadCellProps: {
                                            sx: {
                                                fontSize: "1.2rem",
                                            },
                                        },
                                    },
                                    "mrt-row-select": {
                                        enableColumnActions: false,
                                        enableHiding: true,
                                        size: 50,
                                    },
                                }}
                                enableColumnFilterModes
                                enableGlobalFilterModes
                                //enableColumnResizing
                                enableColumnOrdering
                                enableEditing
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: "flex", gap: "1rem" }}>
                                        <Tooltip arrow placement="left" title="Editar">
                                            <span>
                                                {" "}
                                                <IconButton
                                                    onClick={() => {
                                                        handleEditar(row.original, row.index);
                                                    }}
                                                    disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip arrow placement="right" title="Deletar">
                                            <span>
                                                <IconButton
                                                    color="error"
                                                    //onClick={() => handleOpenDialog(row.id)}
                                                    onClick={() => handleDelete(row.original, row.id)}
                                                    disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                )}
                                renderTopToolbarCustomActions={({ table }) => {
                                    const handleImprimir = async (table) => {};

                                    //const handleReaproveitarArquivoNF = async () => {};
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <Tooltip arrow placement="top-end" title={`Imprimir`}>
                                                <div>
                                                    {" "}
                                                    <Button
                                                        color="info"
                                                        onClick={handleImprimir}
                                                        variant="contained"
                                                        disabled={JSON.parse(localStorage.getItem("imprimir")) === false}
                                                    >
                                                        Imprimir
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                            {/* <Tooltip
                                                arrow
                                                placement="top-end"
                                                title={`Reaproveitar arquivo de nota fiscal para vários itens selecionados, primeiro copie o nome do arquivo na coluna PDF, depois selecione os itens da mesma nota, em seguida click no botão Arquivo NF e depois cole no campo descrição`}
                                            >
                                                <div>
                                                    <Button
                                                        color="success"
                                                        variant="contained"
                                                        disabled={
                                                            JSON.parse(localStorage.getItem("editar")) === false ||
                                                            (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                                        }
                                                        onClick={
                                                            !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                                ? null
                                                                : handleReaproveitarArquivoNF
                                                        }
                                                    >
                                                        Arquivo NF
                                                    </Button>
                                                </div>
                                            </Tooltip> */}
                                        </div>
                                    );
                                }}
                                columns={columns}
                                data={itensEmbalagens}
                                enableRowSelection
                                getRowId={(row) => row.uuid}
                                manualFiltering
                                manualPagination
                                manualSorting
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                              color: "error",
                                              children: "Error loading data",
                                          }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onColumnFilterFnsChange={setColumnFiltersMode}
                                onGlobalFilterChange={setGlobalFilter}
                                onPaginationChange={setPagination}
                                onSortingChange={setSorting}
                                rowCount={rowCount}
                                initialState={{
                                    showColumnFilters: true,
                                    density: "compact",
                                    //columnPinning: { left: ["mrt-row-select"] },
                                }}
                                state={{
                                    columnFilterFns,
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    pagination,
                                    showAlertBanner: isError,
                                    showProgressBars: isRefetching,
                                    sorting,
                                }}
                                //positionToolbarDropZone={"top"}
                                positionExpandColumn={"first"}
                                positionActionsColumn={"first"}
                                positionToolbarAlertBanner="bottom"
                                localization={MRT_Localization_PT_BR}
                            />
                        </ThemeProvider>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações do produto</h4>
                    </div>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <InputNumber
                                    text="Peso Bruto"
                                    type="text"
                                    name="pesoBruto"
                                    placeholder="peso em g"
                                    inputWidth="100px"
                                    title="digite o peso bruto do produto em gramas"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.pesoBruto ? formatarNumero(dados.pesoBruto) : ""}
                                />
                                <InputNumber
                                    text="Peso Líquido"
                                    type="text"
                                    name="pesoLiquido"
                                    placeholder="peso em g"
                                    inputWidth="100px"
                                    title="digite o peso líquido do produto em gramas"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.pesoLiquido ? formatarNumero(dados.pesoLiquido) : ""}
                                />
                                <InputNumber
                                    text="Estoque Mínimo"
                                    type="text"
                                    name="estoqueMinimo"
                                    placeholder="Estoque mínimo operacional"
                                    inputWidth="100px"
                                    title="digite o valor do estoque mínimo do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.estoqueMinimo ? formatarNumero(dados.estoqueMinimo) : ""}
                                />
                                <InputNumber
                                    text="Largura cm"
                                    type="text"
                                    name="largura"
                                    placeholder="cm"
                                    inputWidth="100px"
                                    title="digite a largura em centímetros"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.largura ? formatarNumero(dados.largura) : ""}
                                />
                                <InputNumber
                                    text="Altura cm"
                                    type="text"
                                    name="altura"
                                    placeholder="cm"
                                    inputWidth="100px"
                                    title="digite a altura em centímetros"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.altura ? formatarNumero(dados.altura) : ""}
                                />
                                <InputNumber
                                    text="Comprimento cm"
                                    type="text"
                                    name="comprimento"
                                    placeholder="cm"
                                    inputWidth="100px"
                                    title="digite a comprimento em centímetros"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.comprimento ? formatarNumero(dados.comprimento) : ""}
                                />
                                <InputNumber
                                    text="Volume m³"
                                    type="text"
                                    name="volume"
                                    placeholder="m³"
                                    inputWidth="100px"
                                    title="volume em metros cúbicos m³"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.volume ? dados.volume : ""}
                                    readonly={true}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    text="Comissão"
                                    type="text"
                                    name="comissao"
                                    placeholder="Comissão do produto no movimento"
                                    inputWidth="100px"
                                    title="digite o valor do comissão do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.comissao ? formatarNumero(dados.comissao) : ""}
                                />
                            </div>
                            <div>
                                <Select
                                    name="almoxarifadoId"
                                    title="Almoxarifado padrão para o produto"
                                    text="Almoxarifado"
                                    inputWidth="200px"
                                    options={OptionsAlmoxarifados}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.almoxarifadoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Bem</h4>
                        <div>
                            <Input
                                text="Patrimônio"
                                type="text"
                                name="produtoBens.patrimonio"
                                placeholder=""
                                title="Número do patrimônio"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.patrimonio || ""}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Descrição do BEM"
                                type="text"
                                name="produtoBens.descricao"
                                placeholder="Descrição"
                                title="digite a descrição do BEM"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.descricao || ""}
                                readonly={false}
                            />
                            <Select
                                name="produtoBens.tipo"
                                text="Tipo"
                                title="Determina o tipo do produto"
                                options={EnumBemTipo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.tipo}
                                isEnum={true}
                            />
                            <Checkbox
                                title="Ativa ou Inativa o BEM"
                                text="Ativo"
                                name="produtoBens.ativo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.ativo}
                                checked={produtoBens.ativo === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Imagens do Produto</h4>
                    </div>
                    <div className={formStyles.preview_bandeira}>
                        <div>
                            <Input
                                text="Fotos do Produto"
                                type="file"
                                name="images"
                                handleOnChange={onFileChange}
                                inputWidth="400px"
                                multiple={true}
                                accept=".jpg, .jpeg, .png"
                            />
                        </div>
                        {preview.length > 0
                            ? preview.map((image, index) => (
                                  <img
                                      src={URL.createObjectURL(image)}
                                      alt={dados.images}
                                      key={`${dados.images}+${index}`}
                                      style={{
                                          borderColor: "black",
                                          border: "solid 0.05em",
                                      }}
                                      width={60}
                                      height={80}
                                  />
                              ))
                            : produtoImagems &&
                              produtoImagems.map((imagem, index) => (
                                  <img
                                      src={process.env.REACT_APP_API + "imagens/" + imagem.descricao}
                                      alt={`Imagem ${index + 1}`}
                                      key={imagem.uuid || index}
                                      title={process.env.REACT_APP_API + "imagens/" + imagem.descricao}
                                      style={{
                                          borderColor: "black",
                                          border: "solid 0.05em",
                                          margin: "2px",
                                      }}
                                      loading="lazy"
                                      width={60}
                                      height={80}
                                  />
                              ))}
                    </div>
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}

export default Form;
