import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useFlashMessage from "./useFlashMessage";

import api from "../api";
import * as UsuarioAPI from "../api/usuario";
import * as TabelaAPI from "../api/tabela";

export default function useAuth() {
    const [authenticated, setAuthenticated] = useState(false);
    const [permissaoUsuario, setPermissao] = useState({});
    const [parametro, setParametros] = useState({});
    const { setFlashMessage } = useFlashMessage();

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token && window.location.pathname !== "/") {
            navigate("/Auth", { replace: true });
        }

        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
            setAuthenticated(true);
        }

        if (localStorage.getItem("empresaId")) {
            //permissão de usuario
            permissaoUsuarios(parseInt(localStorage.getItem("empresaId")), parseInt(localStorage.getItem("pessoaId")), "", "");

            //parametros
            parametros(token, parseInt(localStorage.getItem("empresaId")));
        }
    }, [navigate]);

    async function login(user) {
        let msgText = "Login Realizado com successo!";
        let msgType = "success";

        try {
            const { data } = await UsuarioAPI.login(user);
            await authUser(data);
        } catch (error) {
            console.error(error);
            msgText = error.response.data.message;
            msgType = error.response.data.type;
        }

        setFlashMessage(msgText, msgType);
    }

    async function authUser(data) {
        setAuthenticated(true);
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
        localStorage.setItem("token", data.token);
        localStorage.setItem("empresaId", JSON.stringify(data.empresaId));
        localStorage.setItem("empresaIds", JSON.stringify(data.empresaIds));
        localStorage.setItem("Empresa", JSON.stringify(data.empresa));
        localStorage.setItem("matriz", JSON.stringify(data.matriz));
        localStorage.setItem("ufId", JSON.stringify(data.empresa.ufId)); //padrão para limitar a pesquisa inicial dos municipios utiliza em todas as consultas que envolvem UF e MUNICIPIO
        localStorage.setItem("municipioId", data.empresa.municipioId ? JSON.stringify(data.empresa.municipioId) : "2704302");
        localStorage.setItem("ramoAtividade", JSON.stringify(data.empresa.ramoAtividade));
        localStorage.setItem("pessoaId", JSON.stringify(data.pessoaId));
        localStorage.setItem("nome", data.pessoa.nomePrincipal);
        localStorage.setItem("logomarca", JSON.stringify(data.empresa.logomarca));
        localStorage.setItem("razaoSocial", JSON.stringify(data.empresa.razaoSocial));
        localStorage.setItem("nomeFantasia", JSON.stringify(data.empresa.nomeFantasia));

        localStorage.setItem("columnFilterFns", JSON.stringify({}));
        localStorage.setItem("columnFilters", JSON.stringify({}));
        localStorage.setItem("globalFilter", JSON.stringify({}));
        localStorage.setItem("sorting", JSON.stringify({}));
        localStorage.setItem("pagination", JSON.stringify({ pageIndex: 0, pageSize: 10 }));
        localStorage.setItem("path", JSON.stringify(""));
        localStorage.setItem("isAdmin", data.pessoa.pessoaUsuario.isAdmin);

        navigate("/");
    }

    function logout(e) {
        let msgText = "Logout Realizado com successo!";
        let msgType = "success";
        setPermissao(0, 0, 0, 0, "");
        setAuthenticated(false);
        localStorage.clear();
        api.defaults.headers.Authorization = null;

        setFlashMessage(msgText, msgType);

        navigate("/Auth");
    }

    async function permissaoUsuarios(empresaId, pessoaId, menuId, menuSubId) {
        if (empresaId > 0 && empresaId !== undefined) {
            let permissao = await UsuarioAPI.permissoes(empresaId, pessoaId, menuId, menuSubId);

            setPermissao(permissao);
        }
    }

    async function parametros(token, empresaId) {
        if (!empresaId) {
            console.error("empresaId está vazio ou indefinido.");
            return;
        }

        //clg("Chamando API com empresaId:", empresaId);
        let dados = await TabelaAPI.tabela(empresaId, "Parametro", "id");

        //clg("Resposta da API:", dados);

        if (Array.isArray(dados)) {
            dados = dados[0]; // Pega o primeiro elemento do array
            //clg("Primeiro elemento do array:", dados);
        }

        setParametros(dados);
    }

    return { authenticated, login, logout, permissaoUsuario, parametro };
}
