import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/UserContext";
import { BrowserRouter as Router } from "react-router-dom"; // Adicione o Router aqui

import moment from "moment";
import "moment/locale/pt-br";
// Configurar a localidade para português (Brasil)
moment.locale("pt-br");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        {/* Envolvendo a aplicação no Router */}
        <Router>
            {/* O UserProvider vem dentro do Router */}
            <UserProvider>
                <App />
            </UserProvider>
        </Router>
    </React.StrictMode>
);

reportWebVitals();
