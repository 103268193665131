import { useState, useEffect, useCallback, useContext } from "react";
import { Context } from "../../../context/UserContext";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "moment/locale/pt-br";

import { Stack, Button, Tooltip } from "@mui/material";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import DataList from "../../form/DataList";
import Input from "../../form/Input";
import Select from "../../form/Select";
import SelectEmpresa from "../../form/SelectEmpresa";
import Checkbox from "../../form/Checkbox";

import formStyles from "../../form/Form.module.css";

/* api */
import * as DataListAPI from "../../../api/dataList";
import * as EmpresaAPI from "../../../api/empresa";
import * as TabelaAPI from "../../../api/tabela";
import * as OperacaoAPI from "../../../api/operacao";
import * as ProdutoAPI from "../../../api/produto";
import * as SituacaoAPI from "../../../api/situacao";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsForma, OptionsPrazo }) {
    const [dados, setDados] = useState(formData || {});
    const { parametro } = useContext(Context);

    //abas
    const [tabEnabled, setTabEnabled] = useState({});

    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    //para produto vazio preenche valores pré definidos
    if (dados.empresaId === undefined) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    //set datalist
    const [CFOP, setCFOP] = useState(formData?.CFOP);
    const [OptionsCFOP, setOptionsCFOP] = useState([]);

    //options dos selects
    const [OptionsTRF, setTRF] = useState([]); //centros de custos de transferencias entre contas
    const [OptionsCCI, setCCI] = useState([]); //centro de custos da industria
    const [OptionsCCR, setCCR] = useState([]); //centro de custos de receitas
    const [OptionsCCP, setCCP] = useState([]); //centros de custos de despesas
    const [OptionsCCSER, setCCSER] = useState([]); //centros de custos de locação de imoveis ou serviço

    const [OptionsOperacaoEntrada, setOptionsOperacaoEntrada] = useState([]);
    const [OptionsOperacao, setOptionsOperacao] = useState([]);

    const [OptionsSituacao, setOptionsSituacao] = useState([]);
    const [OptionsSituacaoAlmoxarifado, setOptionsSituacaoAlmoxarifado] = useState([]);
    const [OptionsSituacaoMovimento, setOptionsSituacaoMovimento] = useState([]);

    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };
    const consultaOperacao = async (entrada, saida, industria, balanco, manutencao) => {
        let data = await OperacaoAPI.retornaOperacoes(entrada, saida, industria, balanco, manutencao);
        setOptionsOperacao(data);
    };
    const consultaSituacao = async (empresaId, campo) => {
        let data = await SituacaoAPI.situacoesByCampo(empresaId, campo);

        // Mapeamento dos campos para suas respectivas funções
        const actionMap = {
            almoxarifado: setOptionsSituacaoAlmoxarifado,
            movimento: setOptionsSituacaoMovimento,
            //financeiro: setOptionsSituacaoFinanceiro,
            //estoque: setOptionsSituacaoEstoque,
            // Adicione mais campos e funções conforme necessário
        };

        // Verifica se existe uma função associada ao campo
        if (actionMap[campo]) {
            actionMap[campo](data); // Chama a função correspondente passando os dados
        } else {
            console.warn(`Campo desconhecido: ${campo}`); // Caso não exista, exibe um aviso
        }
    };

    //consulta backend
    const consultaCC = useCallback(
        async (
            descricao = null,
            tipo = null,
            combustivel = null,
            manutencao = null,
            industria = null,
            option = null,
            servico = null
        ) => {
            const dado = await EmpresaAPI.centroCustos(
                dados.empresaId,
                descricao,
                tipo,
                combustivel,
                manutencao,
                industria,
                servico
            );

            if (tipo === "Receber") {
                setCCR(dado);
            }
            if (tipo === "Pagar") {
                setCCP(dado);
            }
            if (tipo === "Ambos") {
                setTRF(dado);
            }
            if (industria === true) {
                setCCI(dado);
            }
            if (servico === true) {
                setCCSER(dado);
            }

            return dado;
        },
        [dados.empresaId, setCCR, setCCP, setTRF, setCCI, setCCSER]
    );
    const consultaEnum = async (tabela, campo, setEnum) => {
        const result = await TabelaAPI.enums(tabela, campo);
        // if (setEnum === "OptionsPosition") {
        //     setOptionsPosition(result ? result : []);
        // } else if (setEnum === "OptionsTema") {
        //     setOptionsTema(result ? result : []);
        // }
    };

    useEffect(() => {
        setDados(formData || {});

        consultaSituacao((parametro.tabelasComuns = true ? 1 : dados.empresaId), "movimento");
        consultaSituacao((parametro.tabelasComuns = true ? 1 : dados.empresaId), "almoxarifado");

        consultaCC(null, "Pagar");
        consultaCC(null, "Receber");
        consultaCC(null, null, null, null, null, null, true); //obtem os serviços
        consultaCC(null, null, null, null, true, null, null); //industria
    }, [formData, parametro]);

    useEffect(() => {
        if (dados.Operacao) {
            setTabEnabled({
                parametros: true,
                industria: dados.Operacao.industria,
                servico: dados.Operacao.servico,
                entrada: dados.Operacao.entrada,
                saida: dados.Operacao.saida,
                manutencao: dados.Operacao.manutencao,
                balanco: dados.Operacao.balanco,
            });

            consultaOperacao(
                dados.Operacao.entrada,
                dados.Operacao.saida,
                dados.Operacao.industria,
                dados.Operacao.balanco,
                dados.Operacao.manutencao,
                dados.Operacao.servico
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.Operacao]);

    async function handleChange(e) {
        const camposBooleanos = [
            "ativo",
            //parametros
            "alteraEstoque",
            "transformaMovimento",

            //transformação destino
            "destinoEstoqueBloqueado",
            "destinoConferencia",
            "destinoAlteraEstoque",
            "destinoGeraFinanceiro",
            "destinoContabil",

            //entradas
            "estoqueBloqueado",
            "conferencia",
            "contabil",

            "geraFinanceiro",
            "materiaPrimaEstocaGraos",
            "refugoMateriaPrimaGeraFinanceiro",
            "tabelasComuns",
            "msgCoresDefault",
            "calculaJuros",
        ];
        //configura os chebox
        if (camposBooleanos.includes(e.target.name)) {
            setDados({
                ...dados,
                [e.target.name]: e.target.checked,
            });
        } else if (e.target.name === "centroCustoMateriaPrimaId") {
            setDados({
                ...dados,
                [e.target.name]: e.target.value,
            });
        } else if (e.target.name.startsWith("CFOP.")) {
            setCFOP({ ...CFOP, [e.target.name.split(".")[1]]: e.target.value });

            let result = await consultaDatalist(token, dados.empresaId, "CFOP", "id", "descricao", "", e.target.value);
            setOptionsCFOP(result);

            if (document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id) {
                setDados({
                    ...dados,
                    cfopId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                });
            }
        } else {
            setDados({
                ...dados,
                [e.target.name]: e.target.value,
            });
        }
    }
    async function handleOnBlur(e) {}
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <Tabs>
            <form onSubmit={submit}>
                <h1>{`PARAMÊTROS ${dados.Operacao?.descricao + " - " + dados.Operacao?.sigla}`}</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.parametros} title={"Paramêtros de transformação da Operação"}>
                        Paramêtros
                    </Tab>
                    <Tab disabled={!tabEnabled.entrada} title={"Paramêtros de operações de Entradas"}>
                        Entradas
                    </Tab>
                    <Tab disabled={!tabEnabled.saida} title={"Paramêtros de operações de Saídas"}>
                        Saídas
                    </Tab>
                    <Tab disabled={!tabEnabled.servico} title={"Paramêtros de operações de  Serviço"}>
                        Serviço
                    </Tab>
                    <Tab disabled={!tabEnabled.industria} title={"Paramêtros de operações de Indústria"}>
                        Indústria
                    </Tab>
                    <Tab disabled={!tabEnabled.manutencao} title={"Paramêtros de operações de Manutenção"}>
                        Manutenção
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros da Operação na digitação</h4>
                        <div>
                            <div>
                                <Checkbox
                                    title="Operação atualiza o financeiro"
                                    text="Gera Fianceiro"
                                    name="geraFinanceiro"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.geraFinanceiro}
                                    checked={dados.geraFinanceiro === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Operação contábil"
                                    text="Contábil"
                                    name="contabil"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.contabil}
                                    checked={dados.contabil === true ? "checked" : ""}
                                />
                                <DataList
                                    text="CFOP Descrição"
                                    title={"Vincula o CFOP a operação"}
                                    name="CFOP.descricao"
                                    placeholder={"Digite mais de 3 caracteres para pesquisar"}
                                    inputWidth="300px"
                                    filteredOptions={OptionsCFOP}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={CFOP?.descricao}
                                    required={false}
                                />
                            </div>
                        </div>
                        <h4>Paramêtros da Operação na Transformação</h4>
                        <div>
                            <div>
                                <Checkbox
                                    title="Permite transformar o movimento"
                                    text="Transformar"
                                    name="transformaMovimento"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.transformaMovimento}
                                    checked={dados.transformaMovimento === true ? "checked" : ""}
                                />
                                <Select
                                    title="Operação de destino ao transformar"
                                    text="Operação Destino"
                                    name="destinoOperacaoId"
                                    options={OptionsOperacao}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.destinoOperacaoId}
                                    isEnum={false}
                                    notOptNull={false}
                                    required={false}
                                    inputWidth={"170px"}
                                    disabled={!dados.transformaMovimento}
                                />
                                <Select
                                    name="destinoSituacaoId"
                                    title="Define a situação padrão do movimento destino"
                                    text="Situação Destino"
                                    inputWidth="170px"
                                    options={OptionsSituacaoMovimento}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.destinoSituacaoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Checkbox
                                    title="operação de destino altera estoque"
                                    text="Altera Estoque"
                                    name="destinoAlteraEstoque"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    disabled={!dados.transformaMovimento}
                                    value={!dados.transformaMovimento ? false : dados.destinoAlteraEstoque}
                                    checked={dados.destinoAlteraEstoque === true ? "checked" : ""}
                                />
                                <Input
                                    text="Estoque Destino"
                                    type="range"
                                    name="destinoEstoque"
                                    inputWidth="100px"
                                    title="configura o valor que a operação altera o estoque, -1 subtrai, 0 não altera e 1 acrescenta"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    disabled={!dados.transformaMovimento}
                                    value={!dados.transformaMovimento ? 0 : dados.destinoEstoque}
                                    textAlign="center"
                                    min={-1}
                                    max={1}
                                />
                                <Checkbox
                                    title="Estoque bloqueado na operação de destino"
                                    text="Estoque Bloqueado"
                                    name="destinoEstoqueBloqueado"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.destinoEstoqueBloqueado}
                                    checked={dados.destinoEstoqueBloqueado === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Obriga conferência na operação de destino"
                                    text="Conferência"
                                    name="destinoConferencia"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.destinoConferencia}
                                    checked={dados.destinoConferencia === true ? "checked" : ""}
                                />
                                <Select
                                    name="destinoAlmoxarifadoSituacaoId"
                                    title="Define a situação de entrada no almoxarifado para a operação de destino"
                                    text="Situação Alm Destino"
                                    inputWidth="170px"
                                    options={OptionsSituacaoAlmoxarifado}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.destinoAlmoxarifadoSituacaoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    title="gera o financeiro na operação de destino"
                                    text="Gera Financeiro"
                                    name="destinoGeraFinanceiro"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.destinoGeraFinanceiro}
                                    checked={dados.destinoGeraFinanceiro === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="operação de destino contábil"
                                    text="Contábil"
                                    name="destinoContabil"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.destinoContabil}
                                    checked={dados.destinoContabil === true ? "checked" : ""}
                                />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros de movimentações de entradas</h4>
                        <div>
                            <Select
                                name="situacaoId"
                                title="Define a situação padrão do movimento"
                                text="Situação Movimento"
                                inputWidth="170px"
                                options={OptionsSituacaoMovimento}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.situacaoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="centroCustoId"
                                title={"Define o centro de custo padrão para o movimento"}
                                text="Centro Custo"
                                inputWidth="200px"
                                options={OptionsCCP}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                        <div>
                            <Checkbox
                                title="Informa que o movimento altera o estoque"
                                text="Altera Estoque"
                                name="alteraEstoque"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.alteraEstoque}
                                checked={dados.alteraEstoque === true ? "checked" : ""}
                            />
                            <Input
                                text="Estoque"
                                type="range"
                                name="estoque"
                                inputWidth="100px"
                                title="configura o valor que a operação altera o estqoue, -1 subtrai, 0 não altera e 1 acrescenta"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                disabled={!dados.alteraEstoque}
                                value={!dados.alteraEstoque ? 0 : dados.estoque}
                                textAlign="center"
                                min={-1}
                                max={1}
                            />
                            <Select
                                name="situacaoIdAlmoxarifado"
                                title="Define a situação padrão do movimento"
                                text="Situação Almoxarifado"
                                inputWidth="170px"
                                options={OptionsSituacaoAlmoxarifado}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={!dados.alteraEstoque ? null : dados.situacaoIdAlmoxarifado}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Checkbox
                                title="Estoque bloqueado"
                                text="Estqoue Bloqueado"
                                name="estoqueBloqueado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.estoqueBloqueado}
                                checked={dados.estoqueBloqueado === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Obriga conferência"
                                text="Conferencia"
                                name="conferencia"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.conferencia}
                                checked={dados.conferencia === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros de movimentações de saídas</h4>
                        <div>
                            <Select
                                name="situacaoId"
                                title="Define a situação padrão do movimento"
                                text="Situação Movimento"
                                inputWidth="170px"
                                options={OptionsSituacaoMovimento}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.situacaoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="centroCustoId"
                                title={"Define o centro de custo padrão para o movimento"}
                                text="Centro Custo"
                                inputWidth="200px"
                                options={OptionsCCR}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                        <div>
                            <Checkbox
                                title="Informa que o movimento altera o estoque"
                                text="Altera Estoque"
                                name="alteraEstoque"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.alteraEstoque}
                                checked={dados.alteraEstoque === true ? "checked" : ""}
                            />
                            <Input
                                text="Estoque"
                                type="range"
                                name="estoque"
                                inputWidth="100px"
                                title="configura o valor que a operação altera o estqoue, -1 subtrai, 0 não altera e 1 acrescenta"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                disabled={!dados.alteraEstoque}
                                value={!dados.alteraEstoque ? 0 : dados.estoque}
                                textAlign="center"
                                min={-1}
                                max={1}
                            />
                            <Select
                                name="situacaoIdAlmoxarifado"
                                title="Define a situação padrão do movimento"
                                text="Situação Almoxarifado"
                                inputWidth="170px"
                                options={OptionsSituacaoAlmoxarifado}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={!dados.alteraEstoque ? null : dados.situacaoIdAlmoxarifado}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Checkbox
                                title="Estoque bloqueado"
                                text="Estqoue Bloqueado"
                                name="estoqueBloqueado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.estoqueBloqueado}
                                checked={dados.estoqueBloqueado === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Obriga conferência"
                                text="Conferencia"
                                name="conferencia"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.conferencia}
                                checked={dados.conferencia === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros de movimentações de serviço</h4>
                        <div>
                            <Select
                                name="situacaoId"
                                title="Define a situação padrão do movimento"
                                text="Situação Movimento"
                                inputWidth="170px"
                                options={OptionsSituacaoMovimento}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.situacaoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="centroCustoId"
                                title={"Define o centro de custo padrão para serviços"}
                                text="Centro Custo"
                                inputWidth="200px"
                                options={OptionsCCSER}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros para o modulo de indústria</h4>

                        <div>
                            <Checkbox
                                title="Informa se a entrada de materia prima estoca no almoxarifado de grãos"
                                text="MP Est Grãos"
                                name="materiaPrimaEstocaGraos"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.materiaPrimaEstocaGraos}
                                checked={dados.materiaPrimaEstocaGraos === true ? "checked" : ""}
                            />
                            <Select
                                name="centroCustoMateriaPrimaId"
                                title={"Define o centro de custo padrão para entrada de materia prima"}
                                text="Centro Custo MP"
                                options={OptionsCCI}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoMateriaPrimaId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Checkbox
                                title="Informa se o refugo vai gerar valor a receber no modulo financeiro"
                                text="Refugo MP gera financeiro"
                                name="refugoMateriaPrimaGeraFinanceiro"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.refugoMateriaPrimaGeraFinanceiro}
                                checked={dados.refugoMateriaPrimaGeraFinanceiro === true ? "checked" : ""}
                            />
                            <Select
                                name="centroCustoRefugoMateriaPrimaId"
                                title={"Define o centro de custo padrão para o refugo da materia prima"}
                                text="Centro Custo Refugo"
                                options={OptionsCCI}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoRefugoMateriaPrimaId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                        <div>
                            {" "}
                            <Checkbox
                                title="Informa se a empresa utiliza plantões na produção"
                                text="Plantão"
                                name="plantao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.plantao}
                                checked={dados.plantao === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros de Comuns</h4>
                        <div>
                            <Select
                                name="centroCustoTransferencia"
                                title={"Define o centro de custo padrão para os transferências entre contas"}
                                text="Centro Custo Transferências"
                                inputWidth="200px"
                                options={OptionsTRF}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoTransferencia}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="transferenciaForma"
                                title={"Define a forma padrão para os transferências entre contas"}
                                text="Forma Transferências"
                                inputWidth="200px"
                                options={OptionsForma}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.transferenciaForma}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="transferenciaPrazo"
                                title={"Define o prazo padrão para os transferências entre contas"}
                                text="Prazo Transferências"
                                inputWidth="200px"
                                options={OptionsPrazo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.transferenciaPrazo}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Checkbox
                                title="Permite calcular juros e multa"
                                text="Calcula Juros"
                                name="calculaJuros"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.calculaJuros}
                                checked={dados.calculaJuros === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <Select
                                name="centroCustoComissaoId"
                                title={"Define o centro de custo padrão para as comissões"}
                                text="Centro Custo Comissão"
                                inputWidth="200px"
                                options={OptionsCCP}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoComissaoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                    </div>
                </TabPanel>

                <div>
                    <Stack direction="row" spacing={1}>
                        <Tooltip arrow placement="top-start" title="Voltar">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    navigate(caminhoVoltar, {
                                        ul: urlParams.get("ul"),
                                        li: urlParams.get("li"),
                                        tipo: urlParams.get("tipo"),
                                    })
                                }
                            >
                                Voltar
                            </Button>
                        </Tooltip>
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            //disabled={}
                        >
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}
export default Form;
