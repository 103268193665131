import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import CRUDsAPI from "../../../api/CRUDs";
import * as EmpresaAPI from "../../../api/empresa";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as OperacaoAPI from "../../../api/operacao";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [operacao, setOperacao] = useState({});

    //options
    const [OptionsSituacao, setOptionsSituacao] = useState([]);
    const [OptionsCentroCusto, setOptionsCentroCusto] = useState([]);

    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const operacaoId = urlParams.get("operacaoId");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
    });

    const uuid = urlParams.get("uuid");
    let btnText = "";
    !uuid ? (btnText = "Cadastrar") : (btnText = "Atualizar");

    // Funções
    async function retornaOperacao(uuid) {
        try {
            const resultado = await OperacaoAPI.operacao(uuid);
            setOperacao(resultado);
            setDados((prev) => ({ ...prev, operacaoId: resultado.id }));
            return resultado;
        } catch (erro) {
            // Lide com o erro
            console.error(erro);
        }
    }

    //consultas backend
    const consultaSituacao = async (empresaId, campo) => {
        try {
            const result = await FinanceiroAPI.situacao(empresaId, campo);
            setOptionsSituacao(result);

            // Mapeamento de operacao.sigla para descrições de situação
            const operacaoSituacoes = {
                LIM: "Orçamento",
                // Adicione aqui outras siglas e suas descrições correspondentes
                PDC: "Orçamento",
            };

            if (!dados.situacaoId && operacao.sigla in operacaoSituacoes) {
                const descricaoSituacao = operacaoSituacoes[operacao.sigla];

                // Encontra a situação correspondente à descrição mapeada
                const situacaoEncontrada = result.find((item) => item.descricao === descricaoSituacao);

                if (situacaoEncontrada) {
                    dados.situacaoId = situacaoEncontrada.id; // Define o id da situação correspondente
                } else {
                    console.warn(`Situação com descrição "${descricaoSituacao}" não encontrada.`);
                }
            }

            return result;
        } catch (error) {
            console.error("Erro ao consultar situação:", error);
        }
    };
    const consultaCentroCusto = async () => {
        const result = await EmpresaAPI.centroCustos(dados.empresaId, "", "Receber");
        setOptionsCentroCusto(result);
        return result;
    };
    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        if (uuid) {
            //consulta a API
            CRUDsAPI.movimentoEntradas
                .edit(uuid, operacaoId)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    useEffect(() => {
        retornaOperacao(operacaoUUID);
        consultaSituacao(parametro.tabelasComuns === true ? 1 : dados.empresaId, "movimento");
        consultaCentroCusto();
    }, [operacaoUUID]);
    async function managerDados(obj) {
        let urlNavigate = `/MovimentoEntradas?${params.toString()}`;
        // Filtra os itens onde pedido é igual a true e atualiza o movimentoItems
        obj.movimentoItems = obj.movimentoItems.filter((item) => item.pedido === true);

        try {
            let response;

            if (uuid) {
                response = await CRUDsAPI.movimentoEntradas.editar(obj);
            } else {
                response = await CRUDsAPI.movimentoEntradas.cadastrar(obj);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = `/MovimentoEntradas/CreateOrEdit?uuid=${response.data.uuid}`;
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                navigate(urlNavigate);
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                operacao={operacao}
                OptionsSituacao={OptionsSituacao}
                OptionsCentroCusto={OptionsCentroCusto}
                caminhoVoltar={`/movimentoEntradas`}
            />
        </section>
    );
};
export default CreateOrEdit;
