import React, { useEffect, useMemo, useState, useCallback, useContext } from "react";
import { Context } from "../../../context/UserContext";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import tratar from "../../../utils/retornaTratamentoURL";
import CRUDsAPI from "../../../api/CRUDs";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

const Index = () => {
    const [municipios, setMunicipios] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const navigate = useNavigateParams();

    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul") || 10;
    const li = urlParams.get("li") || 7;
    const tipo = urlParams.get("tipo");

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    const [tableData, setTableData] = useState(() => municipios);
    const [validationErrors, setValidationErrors] = useState({});

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            //send/receive api updates here, then refetch or update local table data for re-render
            setTableData([...tableData]);
            exitEditingMode(); //required to exit editing mode and close modal
        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                // eslint-disable-next-line no-restricted-globals
                !confirm(`Tem certeza de que deseja excluir ${row.getValue("descricao")}`)
            ) {
                return;
            }

            //send api delete request here, then refetch or update local table data for re-render
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        const fetchData = async () => {
            if (!municipios.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            try {
                CRUDsAPI.municipio
                    .listar(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? [])
                    )
                    .then((response) => {
                        setMunicipios(response.data.data);
                        setRowCount(response.data.meta.totalRowCount);
                    });
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, ul, li]);

    const columns = useMemo(
        () => [
            {
                id: "Municipio",
                header: "Municípios do Brasil",
                columns: [
                    {
                        accessorKey: "id",
                        header: "Código",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "UF.uf",
                        header: "Estado",
                        size: 250,
                        Cell: ({ renderedCellValue, row }) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                }}
                            >
                                {row.original.UF.bandeira !== null && (
                                    <img
                                        alt=""
                                        height={30}
                                        width={50}
                                        src={process.env.REACT_APP_API + "imagens/" + row.original.UF.bandeira}
                                        loading="lazy"
                                        style={{
                                            borderColor: "black",
                                            border: "solid 0.1em",
                                        }}
                                    />
                                )}
                                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                                <span>{row.original.UF.uf}</span>
                            </Box>
                        ),
                    },
                    {
                        accessorKey: "descricao",
                        header: "Município",
                        size: 250,
                        Cell: ({ renderedCellValue, row }) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                }}
                            >
                                {row.original.bandeira !== null && (
                                    <img
                                        alt=""
                                        height={30}
                                        width={50}
                                        src={process.env.REACT_APP_API + "imagens/" + row.original.bandeira}
                                        loading="lazy"
                                        style={{
                                            borderColor: "black",
                                            border: "solid 0.1em",
                                        }}
                                    />
                                )}
                                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                                <span>{row.original.descricao}</span>
                            </Box>
                        ),
                    },
                ],
            },
        ],
        []
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            {" "}
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 100,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    onEditingRowSave={handleSaveRowEdits}
                    onEditingRowCancel={handleCancelRowEdits}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                            <Tooltip arrow placement="left" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() =>
                                            navigate("/Municipio/CreateOrEdit", {
                                                uuid: row.id,
                                                ul: ul,
                                                li: li,
                                                tipo: tipo,
                                                btnText: urlParams.get("btnText"),
                                            })
                                        }
                                        disabled={JSON.parse(localStorage.getItem("editar")) === false}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Deletar">
                                <IconButton
                                    color="error"
                                    disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                    onClick={() => handleDeleteRow(row)}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                            }}
                        >
                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                <div>
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            navigate("/Municipio/Create", {
                                                ul: urlParams.get("ul"),
                                                li: urlParams.get("li"),
                                            })
                                        }
                                        variant="contained"
                                        disabled={true}
                                    >
                                        Novo
                                    </Button>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    columns={columns}
                    data={municipios}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    renderDetailPanel={({ row }) => (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                        >
                            {row.original.bandeira !== null && (
                                <img
                                    alt="bandeira"
                                    height={200}
                                    src={process.env.REACT_APP_API + "imagens/" + row.original.bandeira}
                                    loading="lazy"
                                    style={{
                                        borderColor: "black",
                                        border: "solid 0.1em",
                                    }}
                                    //style={{ borderRadius: "50%" }}
                                />
                            )}
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant="h4">Munícipio: {row.original.descricao}</Typography>
                            </Box>
                        </Box>
                    )}
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
