import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";
import FormBaixarLancamento from "./FormBaixarLancamento";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as LancamentosAPI from "../../../api/lancamentos";

const BaixaLancamento = () => {
    const [lancamento, setLancamentos] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    // Acesse os valores desejados do estado usando o hook useSelector
    const selectedUUIDs = useSelector((state) => state.selectedUUIDs);

    useEffect(() => {
        // Faça o uso dos valores obtidos
        if (selectedUUIDs && selectedUUIDs.length > 0) {
            LancamentosAPI.paraBaixar(selectedUUIDs)
                .then((response) => {
                    setLancamentos(response.data);
                })
                .catch((error) => {
                    setFlashMessage(error.message, "error");
                    return error;
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, selectedUUIDs]);

    async function managerBaixa(lancamento, novoLancamento) {
        try {
            const response = await LancamentosAPI.baixas({ ...lancamento, ...novoLancamento });

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/Lancamentos", {
                        ul: urlParams.get("ul"),
                        li: urlParams.get("li"),
                        tipo: urlParams.get("tipo"),
                        hasChanged: true,
                    });
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <FormBaixarLancamento
                token={token}
                handleSubmit={managerBaixa}
                formData={lancamento}
                caminhoVoltar={"/Lancamentos"}
            />
        </section>
    );
};
export default BaixaLancamento;
