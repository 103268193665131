import { useState, useMemo, useEffect, useCallback } from "react";
import "react-tabs/style/react-tabs.css";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* funções */
import { retornaData } from "../../../utils/retornaDataFormatada";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import DataList from "../../form/DataList";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import * as DataListAPI from "../../../api/dataList";
import * as TabelaAPI from "../../../api/tabela";
import * as ContaAPI from "../../../api/conta";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState(formData || {});
    const [itens, setItens] = useState([]); //itens da grid
    //const [chavePIX, setChavePIX] = useState([]);
    const [banco, setBanco] = useState(formData?.Banco || {});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    const [novaChave, setNovaChave] = useState({
        contaId: dados.id,
        descricao: null,
        tipo: "CPF",
        principal: true,
        ativo: true,
        funcao: "adicionar",
    });

    //datalist
    const [OptionsBanco, setOptionsBanco] = useState([]);

    //Enums
    const [EnumTipoChave, setEnumTipoChave] = useState([]);

    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "chavePIX") {
            field === "tipo" && setEnumTipoChave(result ? result : []);
        }
    };

    useEffect(() => {
        setDados(formData || {});
        setBanco(formData?.Banco || {});
        //setChavePIX(formData?.chavePIXes || {});
        setItens(formData?.chavePIXes || []);

        /* ENUM */
        consultaEnum("chavePIX", "tipo", null);
    }, [formData]);

    useEffect(() => {
        setNovaChave((prev) => ({
            ...prev, // Mantém as outras propriedades do objeto anterior
            contaId: dados.id, // Atualiza apenas a contaId
        }));
    }, [dados.id]);

    async function handleChange(e) {
        if (e.target.name.startsWith("Banco.")) {
            if (e.target.name.split(".")[1] === "descricao") {
                setBanco({
                    ...banco,
                    [e.target.name.split(".")[1]]: e.target.value,
                });

                //carrega o datalist
                if (e.target.value?.length > 1) {
                    let result = await DataListAPI.dataList(
                        !dados.empresaId ? document.getElementById("empresaId").value : dados.empresaId,
                        "Banco",
                        "id",
                        "descricao",
                        "",
                        e.target.value
                    );
                    setOptionsBanco(result);
                }

                const valor = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;
                //seta o objeto
                setDados({
                    ...dados,
                    bancoId: !valor ? null : valor,
                });
            }
        } else if (e.target.name.startsWith("novaChave.")) {
            const campo = e.target.name.split(".")[1];
            let valor = e.target.value ? e.target.value : null;

            if (campo === "ativo" || campo === "principal") {
                valor = e.target.checked;
            } else if (campo === "tipo") {
                valor = e.target.options[e.target.selectedIndex].text;
            }
            setNovaChave((prev) => ({
                ...prev,
                [campo]: valor,
            }));
        } else if (e.target.name === "ativo" || e.target.name === "balancete" || e.target.name === "imobilizado") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name === "bancoId") {
            setDados({ ...dados, [e.target.name]: e.target.value });
            const { name, value } = e.target;
            setDados((prevConta) => ({
                ...prevConta,
                [name]: value,
            }));
        } else if (e.target.name === "saldoInicial") {
            setDados({
                ...dados,
                [e.target.name]: formatarNumeroDecimal(e.target.value),
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId").value,
                "Conta",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Contas/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        handleSubmit(dados);
    };

    /* Manutenção da GRID */

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    const contaId = dados.id ? dados.id : null;

    const fetchData = useCallback(async () => {
        if (!itens.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        if (dados.id) {
            try {
                const response = await ContaAPI.listarChavesPIX(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? []),
                    contaId ?? ""
                );

                setItens(response.data.data);
                setRowCount(response.data.meta.totalRowCount);
                setIsError(false); // Move o tratamento de erro para aqui
            } catch (error) {
                setIsError(true);
                console.error(error);
            } finally {
                setIsLoading(false);
                setIsRefetching(false);
            }
        } else {
            setIsLoading(false);
            setIsRefetching(false);
        }
    }, [
        itens.length,
        dados.id,
        pagination.pageIndex,
        pagination.pageSize,
        columnFilters,
        columnFilterFns,
        globalFilter,
        sorting,
        contaId,
    ]);

    const limparChave = () => {
        setNovaChave({
            contaId: dados.id,
            descricao: null,
            tipo: "CPF",
            principal: true,
            ativo: true,
            funcao: "adicionar",
        });
    };
    const CreateOrEditChave = async (novaChave) => {
        try {
            const result = await ContaAPI.createChavePIX(novaChave);
            setFlashMessage(result.message, result.type);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message);
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleChave = async () => {
        if (novaChave.funcao === "editar") {
            if (novaChave.uuid) {
                CreateOrEditChave(novaChave);
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens[novaChave.index] = {
                        ...prevItens[novaChave.index],
                        ...novaChave,
                    };
                    return novosItens;
                });
            }
        } else if (novaChave.funcao === "deletar") {
            if (novaChave.uuid) {
                try {
                    const response = await ContaAPI.deletarChavePIX(novaChave.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novaChave.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar
            if (dados.id) {
                //verificar duplicidade
                const result = await ContaAPI.duplicidadeChavePIX(novaChave.contaId, novaChave.descricao);

                if (result && novaChave.funcao === "adicionar") {
                    setFlashMessage("Registo em duplicidade!", "warning");
                    handleEditar(result);
                    return;
                }

                CreateOrEditChave(novaChave);
            } else {
                const novoItemArray = [
                    ...itens,
                    {
                        uuid: novaChave.uuid,
                        contaId: novaChave.contaId,
                        tipo: novaChave.tipo,
                        descricao: novaChave.descricao,
                        principal: novaChave.principal,
                        ativo: novaChave.ativo,
                    },
                ];

                setItens(novoItemArray);
                setRowCount(novoItemArray.length);

                setDados((prev) => ({
                    ...prev, // Mantém as outras propriedades do objeto
                    chavePIX: novoItemArray, //Adiciona o novo array ou atualiza o existente
                }));
            }
        }

        limparChave();

        fetchData();
    };
    const handleEditar = (originalRow, row) => {
        // Define os dados para o novo produto
        const novoChaveData = {
            uuid: originalRow.uuid,
            contaId: originalRow.contaId,
            tipo: originalRow.tipo,
            descricao: originalRow.descricao,
            principal: originalRow.principal,
            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        // Atualize o estado da nova chave
        setNovaChave(novoChaveData);

        return;
    };
    const handleDelete = (originalRow, row) => {
        const novaChaveData = {
            uuid: originalRow.uuid,
            contaId: originalRow.contaId,
            descricao: originalRow.descricao,
            tipo: originalRow.tipo,
            principal: originalRow.principal,
            ativo: originalRow.ativo,
            funcao: "deletar",
            index: row,
        };

        // Atualize o estado
        setNovaChave(novaChaveData);
    };
    /* código da grid */
    const columns = useMemo(
        () => [
            {
                id: "chavePIX",
                header: "Chaves PIX cadastradas",
                columns: [
                    {
                        accessorKey: "descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "tipo",
                        header: "Tipo",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <form onSubmit={submit}>
            <h1>Gestão de Contas Financeiras</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    notOptNull={true}
                    required={true}
                />
                <DataList
                    text="Selecione o Banco"
                    name="Banco.descricao"
                    inputWidth="250px"
                    filteredOptions={OptionsBanco}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={banco.descricao || ""}
                    required={true}
                />

                <Input
                    text="Descrição"
                    type="text"
                    name="descricao"
                    placeholder="nome do conta"
                    title="Digite o nome do conta"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
                <Input
                    text="Agência"
                    type="text"
                    name="agencia"
                    placeholder="Agência"
                    title="Digite o nome da agência"
                    inputWidth="80px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.agencia || ""}
                />
                <Input
                    text="Operação"
                    type="text"
                    name="operacao"
                    placeholder="Operação"
                    title="Digite a operação da conta"
                    inputWidth="80px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.operacao || ""}
                />
                <Input
                    text="Conta"
                    type="text"
                    name="conta"
                    placeholder="Conta"
                    title="informe a conta"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.conta || ""}
                    required
                />
                <Input
                    text="Início"
                    type="date"
                    name="dataInicial"
                    placeholder="data abetura da coonta"
                    title="Data de abertura da empresa"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={(dados.dataInicial && retornaData(dados.dataInicial)) || retornaData(moment())}
                />
                <InputNumber
                    text="Saldo"
                    type="number"
                    name="saldoInicial"
                    placeholder="Saldo R$"
                    inputWidth="80px"
                    title="informe o saldo inicial da conta"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.saldoInicial ? formatarNumero(dados.saldoInicial) : ""}
                />
                <Checkbox
                    title="Informa que a conta será apresentada no balancete da empresa"
                    text="Balancete"
                    name="balancete"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.balancete}
                    checked={dados.balancete === true ? "checked" : ""}
                />
                <Checkbox
                    title="Informa que a conta está vinculada a um ativo imobilizado"
                    text="Imobilizado"
                    name="imobilizado"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.imobilizado}
                    checked={dados.imobilizado === true ? "checked" : ""}
                />
                <Checkbox
                    title="ATIVO ou Inativo"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "1rem",
                    marginLeft: "1rem",
                }}
            >
                <h4>Cadastro de chaves PIX</h4>
            </div>
            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                    }}
                >
                    <Input
                        text="Descricao"
                        type="text"
                        name="novaChave.descricao"
                        placeholder="Chave PIX"
                        title="Digite a chave PIX"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={novaChave.descricao || ""}
                    />
                    <Select
                        name="novaChave.tipo"
                        title={"Escolha o tipo de chave PIX"}
                        text="Tipo"
                        options={EnumTipoChave}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={novaChave.tipo}
                        isEnum={true}
                        notOptNull={false}
                    />
                    <Checkbox
                        title="Chave principal"
                        text="Principal"
                        name="novaChave.principal"
                        type="checkbox"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={novaChave.principal}
                        checked={novaChave.principal === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Ativo quando marcado"
                        text="Ativo"
                        name="novaChave.ativo"
                        type="checkbox"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={novaChave.ativo}
                        checked={novaChave.ativo === true ? "checked" : ""}
                    />
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => {
                            setNovaChave({
                                uuid: null,
                                contaId: dados.id,
                                tipo: "CPF",
                                descricao: null,
                                principal: true,
                                ativo: true,
                                funcao: "adicionar",
                            });
                        }}
                        style={{ marginLeft: "10px" }}
                    >
                        Limpar
                    </Button>
                    <Button
                        variant="contained"
                        color={novaChave.funcao === "deletar" ? "error" : "info"}
                        onClick={() => handleChave(novaChave)}
                        disabled={novaChave.descricao === null || novaChave.descricao === undefined ? true : false}
                        style={{ marginLeft: "5px" }}
                    >
                        {novaChave.funcao}
                    </Button>
                </div>
            </div>

            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 100,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                            <Tooltip arrow placement="left" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() => handleEditar(row.original, row.id)}
                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Deletar">
                                <span>
                                    <IconButton
                                        color="error"
                                        //onClick={() => handleOpenDialog(row.id)}
                                        onClick={() => handleDelete(row.original, row.id)}
                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                    >
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                            }}
                        >
                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                <div>
                                    {" "}
                                    <Button
                                        color="secondary"
                                        onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                        variant="contained"
                                        disabled={
                                            true
                                            // localStorage.getItem("incluir") ===
                                            // "true"
                                            //     ? false
                                            //     : true
                                        }
                                    >
                                        Novo
                                    </Button>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    columns={columns}
                    data={itens}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>

            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
