import * as UsuarioAPI from "../api/usuario";
import moment from "moment";

import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

// Função utilitária para obter permissões do usuário
export const obterPermissoesUsuario = async (pessoaId, empresaId, ul, li) => {
    try {
        const permissoes = await UsuarioAPI.permissoes(pessoaId, empresaId, ul, li);

        if (permissoes.length === 0) {
            // Redirecionar para a página inicial se o usuário não tiver permissões
            return { sucesso: false, mensagem: "Usuário sem permissões." };
        }

        // Armazenar permissões no localStorage
        localStorage.setItem("incluir", permissoes[0].incluir);
        localStorage.setItem("editar", permissoes[0].editar);
        localStorage.setItem("imprimir", permissoes[0].imprimir);
        localStorage.setItem("cancelar", permissoes[0].cancelar);
        localStorage.setItem("cancelarBaixa", permissoes[0].cancelarBaixa);
        localStorage.setItem("baixar", permissoes[0].baixar);
        localStorage.setItem("transformar", permissoes[0].transformar);
        localStorage.setItem("custos", permissoes[0].custos);

        return { sucesso: true, mensagem: "Permissões obtidas com sucesso." };
    } catch (erro) {
        // Lidar com erros, por exemplo, logar ou lançar uma exceção
        console.error("Erro ao obter permissões:", erro);
        return { sucesso: false, mensagem: "Erro ao obter permissões." };
    }
};

export const corrigirDataColumnFilters = (columnFilters, objeto) => {
    const idsTipoData = objeto && objeto.length > 0 ? objeto : ["data", "dataBaixa", "dataVencimento", "dataLancamento"];

    // Formata os filtros de datas apenas se necessário
    const formattedFilters = columnFilters.map((filter) => {
        if (idsTipoData.includes(filter.id)) {
            const formattedValue = Array.isArray(filter.value)
                ? filter.value.map((val) => moment(val).add(3, "hours").startOf("day").format("YYYY-MM-DD"))
                : moment(filter.value).add(3, "hours").startOf("day").format("YYYY-MM-DD");
            return { ...filter, value: formattedValue };
        }
        return filter;
    });

    return formattedFilters;
};
/**
 * Transforma o array string em objeto para o dataPicker mostrar a data corretamente
 * @param {*} columnFilters
 * @param {*} objeto
 * @returns
 */
export const corrigirDataColumnFiltersObjeto = (columnFilters, objeto) => {
    const dateIds = objeto && objeto.length > 0 ? objeto : ["data", "dataBaixa", "dataVencimento", "dataLancamento", "dataBaixa"];

    // Formata os filtros de datas apenas se necessário
    const formattedFilters = columnFilters.map((filter) => {
        if (dateIds.includes(filter.id) && Array.isArray(filter.value)) {
            return { ...filter, value: filter.value.map((val) => new Date(val)) };
        }

        return filter;
    });

    return formattedFilters;
};
