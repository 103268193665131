import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { Context } from "../../context/UserContext";
import tableTheme from "./tableTheme";

const useCustomTheme = (modoTema) => {
    const globalTheme = useTheme();
    const context = useContext(Context);

    // Acessar a propriedade 'parametro' diretamente
    const parametro = context?.parametro;

    //retorna o tema padrão ainda vou criar uma opção no cadastro de usuario para tem tema por usuário
    const darkModeTheme = {
        ...globalTheme,
        palette: {
            ...globalTheme.palette,
            mode: parametro?.tema || "dark",
        },
    };

    return tableTheme(darkModeTheme);
};

export default useCustomTheme;
