import api from ".";

export const Duplicar = (selectedUUIDs) => api.post("/centroCustoLimite/Duplicar", selectedUUIDs);

export const relatorioLimiteCentroCustoPorMes = (url) =>
    api.post(
        `/centroCustoLimite/relatorioLimiteCentroCustoPorMes/?${url.toString()}`,
        {},
        {
            responseType: "arraybuffer",
        }
    );
