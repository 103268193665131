import { useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as CCL_API from "../../../api/centroCustoLimite";

const Duplicar = () => {
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();

    const selectedUUIDs = useSelector((state) => state.selectedUUIDs);
    const hasExecuted = useRef(false); // Controle de execução

    const managerDuplicar = useCallback(async () => {
        const urlParams = new URLSearchParams(location.search);
        try {
            const { data, status } = await CCL_API.Duplicar(selectedUUIDs);

            setFlashMessage(data.message, status === 200 ? "success" : "error");

            if (status === 200 || status === 201) {
                navigate("/centroCustoLimites", {
                    ul: urlParams.get("ul"),
                    li: urlParams.get("li"),
                    tipo: urlParams.get("tipo"),
                });
            }
        } catch (error) {
            setFlashMessage("Erro ao duplicar: " + error.message, "error");
        }
    }, [selectedUUIDs, location.search, navigate, setFlashMessage]);

    useEffect(() => {
        if (!hasExecuted.current && selectedUUIDs?.length) {
            hasExecuted.current = true; // Marque como executado
            managerDuplicar();
        }
    }, [selectedUUIDs, managerDuplicar]);

    return null;
};

export default Duplicar;
