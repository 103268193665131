import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Stack, Button } from "@mui/material";

import Select from "../../form/Select";
import SelectEmpresa from "../../form/SelectEmpresa";
import SelectUsuario from "../../form/SelectUsuario";
import Checkbox from "../../form/Checkbox";

//utils
import * as EmpresaAPI from "../../../api/empresa";
import * as UsuarioAPI from "../../../api/usuario";
import * as TabelaAPI from "../../../api/tabela";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsUsuarios }) {
    const [dados, setDados] = useState(formData || "");
    const [empresas, setEmpresa] = useState(OptionsEmpresa || []);
    const [usuarios, setUsuario] = useState(OptionsUsuarios || []);
    const [menus, setMenus] = useState([]);
    const [menuSubs, setMenuSub] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    if (dados.empresaId === undefined) {
        dados.empresaId = localStorage.getItem("empresaId");
    }

    //carregar API's Externas
    const consultaEmpresa = async () => {
        let empresa = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(empresa);
    };

    const consultaUsuario = async (empresaId = "") => {
        let usuario = await UsuarioAPI.usuario("", empresaId);
        setUsuario(usuario);
    };

    const consultaMenu = async () => {
        let menu = await TabelaAPI.menu("");
        setMenus(menu);
    };

    const consultaMenuSub = async (menuId, descricao) => {
        let menuSub = await TabelaAPI.menuSub(menuId, descricao);
        setMenuSub(menuSub);
    };

    useEffect(() => {
        dados.menuId && consultaMenuSub(dados.menuId);
    }, [dados.menuId]);

    useEffect(() => {
        setDados(formData || {});
        consultaEmpresa(formData?.Empresa);
        consultaUsuario(formData?.Usuario);
        consultaMenu(formData?.Menu?.descricao);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    function handleChange(e) {
        e.target.name === "empresaId" && consultaUsuario(e.target.value);
        e.target.name === "menuId" && consultaMenuSub(e.target.value);

        if (
            e.target.name === "visualizar" ||
            e.target.name === "incluir" ||
            e.target.name === "editar" ||
            e.target.name === "imprimir" ||
            e.target.name === "cancelar" ||
            e.target.name === "cancelarBaixa" ||
            e.target.name === "baixar" ||
            e.target.name === "transformar" ||
            e.target.name === "custos" ||
            e.target.name === "ativo"
        ) {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    function handleOnBlur(e) {
        e.target.name === "empresaId" && consultaUsuario(e.target.value);
        e.target.name === "menuId" && consultaMenuSub(e.target.value);
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        if (!dados.empresaId && document.getElementById("empresaId").value !== undefined) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        if (!dados.pessoaId && document.getElementById("pessoaId").value !== undefined) {
            dados.pessoaId = parseInt(document.getElementById("pessoaId").value);
        }
        if (!dados.menuId && document.getElementById("menuId").value !== undefined) {
            dados.menuId = parseInt(document.getElementById("menuId").value);
        }
        if (!dados.menuSubId && document.getElementById("menuSubId").value !== undefined) {
            dados.menuSubId = parseInt(document.getElementById("menuSubId").value);
        }
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de Permissões</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={empresas}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                />
                <SelectUsuario
                    text="Selecione o Usuário"
                    name="pessoaId"
                    options={usuarios}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.pessoaId || ""}
                    notOptNull
                />
                <Select
                    text="Selecione o Menu"
                    name="menuId"
                    options={menus}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.menuId || ""}
                    isEnum={false}
                />
                <Select
                    text="Selecione o SubMenu"
                    name="menuSubId"
                    options={menuSubs}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.menuSubId || ""}
                    isEnum={false}
                />
            </div>
            <div>
                <Checkbox
                    title="Quando marcado permitirá VISUALIZAR"
                    text="Visualizar"
                    name="visualizar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.visualizar}
                    checked={dados.visualizar === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá INCLUIR"
                    text="Incluir"
                    name="incluir"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.incluir}
                    checked={dados.incluir === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá EDITAR"
                    text="Editar"
                    name="editar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.editar}
                    checked={dados.editar === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá IMPRIMIR"
                    text="Imprimir"
                    name="imprimir"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.imprimir}
                    checked={dados.imprimir === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá TRANSFORMAR"
                    text="Transformar"
                    name="transformar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.transformar}
                    checked={dados.transformar === true ? "checked" : ""}
                    disabled={!dados.menuSub?.Operacao?.transformaMovimento}
                />
                <Checkbox
                    title="Quando marcado permitirá visualizar custos"
                    text="Custos"
                    name="custos"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.custos}
                    checked={dados.custos === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá CANCELAR"
                    text="Cancelar"
                    name="cancelar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.cancelar}
                    checked={dados.cancelar === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá BAIXAR, funciona apenas no modulo FINANCEIRO"
                    text="Baixar"
                    name="baixar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.baixar}
                    checked={dados.baixar === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá CANCELAR BAIXAR, funciona apenas no modulo FINANCEIRO"
                    text="Cancelar Baixa"
                    name="cancelarBaixa"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.cancelarBaixa}
                    checked={dados.cancelarBaixa === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado a permissão esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            navigate(caminhoVoltar, {
                                state: {
                                    ul: urlParams.get("ul"),
                                    li: urlParams.get("li"),
                                    tipo: urlParams.get("tipo"),
                                },
                            })
                        }
                    >
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}

export default Form;
