import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import Input from "../../form/Input";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

//import formStyles from "./Form.module.css";
import formStyles from "../../form/Form.module.css";

/* funções */
import { retornaDataHora, retornaData } from "../../../utils/retornaDataFormatada";
import * as VerificadoresAPI from "../../../api/verificadores";

import maskCPForCNPJ from "../../../utils/maskCPForCNPJ";
import maskCEP from "../../../utils/maskCEP";

function Form({
    token,
    handleSubmit,
    formData,
    btnText,
    caminhoVoltar,
    EnumTipo,
    EnumRamoAtividade,
    ufs,
    municipios,
    OptionsMatriz,
}) {
    const [dados, setDados] = useState({});
    const [preview, setPreview] = useState([]);
    const [Optionsmunicipios, setMunicipio] = useState([]);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigate();

    const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

    const receitaWS = async (cnpj) => {
        const result = await VerificadoresAPI.cnpj(cnpj);

        if (result.message && result.type) {
            setFlashMessage(result.message, result.type);
            navigate("/Empresa");
        }
        setDados({
            ...dados,
            razaoSocial: result.nome,
            nomeFantasia: result.fantasia,
            abertura: retornaData(result.abertura),
            ultima_atualizacao: retornaDataHora(result.ultima_atualizacao),
            email: result.email,
            cep: result.cep,
            uf: result.uf,
            ufId: result.ufId,
            municipioId: result.municipioId,
            bairro: result.bairro,
            logradouro: result.logradouro,
            numero: result.numero,
            complemento: result.complemento,
            telefone: result.telefone,
            tipo: result.tipo,
        });

        consultaMUNICIPIO(result.ufId);
    };
    const consultaCEP = async (cep) => {
        const result = await VerificadoresAPI.cep(cep);
        if (result.type === "error") {
            setFlashMessage(result.message, result.type);
        } else {
            setDados({
                ...dados,
                ufId: result.ufId,
                municipioId: result.municipioId,
                bairro: result.neighborhood,
                logradouro: result.street,
            });
        }
        consultaMUNICIPIO(result.ufId);
    };
    const consultaMUNICIPIO = async (ufId, uf, descricao) => {
        let opt = await VerificadoresAPI.municipio(ufId, uf, descricao);
        setMunicipio(opt);
    };

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    useEffect(() => {
        setDados(formData || {});
        setMunicipio(municipios);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setDados({ ...dados, logomarca: [...e.target.files] });
    }
    async function handleChange(e) {
        if (e.target.nme === "cnpj" || e.target.name === "cep") {
            setDados({
                ...dados,
                [e.target.name]: onlyNumbers(e.target.value),
            });
        } else if (e.target.name === "tipo") {
            e.target.value === "FILIAL"
                ? setDados({
                      ...dados,
                      tipo: e.target.options[e.target.selectedIndex].text,
                      possuiFilial: false,
                  })
                : setDados({
                      ...dados,
                      tipo: e.target.options[e.target.selectedIndex].text,
                  });
        } else if (e.target.name === "ramoAtividade") {
            setDados({
                ...dados,
                ramoAtividade: e.target.options[e.target.selectedIndex].text,
            });
        } else if (e.target.name === "ufId") {
            setDados({
                ...dados,
                ufId: e.target.value,
            });
            consultaMUNICIPIO(e.target.value);
        } else if (e.target.name === "municipioId") {
            setDados(...dados, { municipioId: e.target.value });
        } else if (e.target.name === "possuiFilial") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else {
            setDados({
                ...dados,
                [e.target.name]: e.target.value,
            });
        }
    }
    function handleOnBlur(e) {
        if (e.target.name === "cnpj") {
            if (e.target.value) {
                dados.cnpj = onlyNumbers(e.target.value);
            }
            if (dados.cnpj?.length !== 11 && dados.cnpj?.length !== 14) {
                setFlashMessage("O campo não é CPF nem CNPJ?", "error");
            }
            if (dados.cnpj?.length === 14) {
                // && btnText === "Cadastrar"
                //verifica se a empresa existe no banco de dados
                receitaWS(dados.cnpj, token);
            }
        }
        if (e.target.name === "cep") {
            if (e.target.value) {
                dados.cep = onlyNumbers(e.target.value);
            }

            if (dados.cep?.length === 8) {
                consultaCEP(dados.cep, token);
            } else if (dados.cep?.length > 0 && dados.cep?.length !== 8) {
                setFlashMessage("CEP inválido!!", "warning");
            } else {
            }
        }
    }
    function handleOnFocus(e) {
        if (e.target.name === "ufId") {
            consultaMUNICIPIO(e.target.value);
        }
    }
    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de Empresas</h1>
            <div className={formStyles.preview_logomarca}>
                <div>
                    <Input
                        text="Logomarca da Empresa"
                        type="file"
                        name="logomarca"
                        handleOnChange={onFileChange}
                        multiple={true}
                    />
                </div>
                {preview.length > 0
                    ? preview.map((image, index) => (
                          <img src={URL.createObjectURL(image)} alt={dados.logomarca} key={`${dados.logomarca}+${index}`} />
                      ))
                    : dados.logomarca && <img src={process.env.REACT_APP_API + "imagens/" + dados.logomarca} alt={dados.cnpj} />}
            </div>
            <div>
                <Input
                    text="CPF ou CNPJ"
                    type="text"
                    name="cnpj"
                    placeholder="CPF ou CNPJ da empresa"
                    title="Digite o CPF ou CNPJ da empresa, ao sair desse campo caso seja um CNPJ o sistema fará uma consulta no site da Receita Federal do Brasil."
                    minlength={14}
                    maxlength={18}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.cnpj ? maskCPForCNPJ(dados.cnpj) : ""}
                    required
                />
                <Input
                    text="Razão Social"
                    type="text"
                    name="razaoSocial"
                    placeholder="razão social da empresa"
                    title="Digite a razão social da empresa"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.razaoSocial || ""}
                    required
                />
                <Input
                    text="Nome Fantasia"
                    type="text"
                    name="nomeFantasia"
                    placeholder="Nome Fantasia da empresa"
                    title="Digite a Nome Fantasia da empresa"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.nomeFantasia || ""}
                    required
                />
            </div>
            <div>
                <Input
                    text="abertura"
                    type="date"
                    name="abertura"
                    placeholder="abertura da empresa"
                    title="Data de abertura da empresa"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={(dados.abertura && retornaData(dados.abertura)) || retornaData(moment())}
                />
                <Input
                    text="última atualização"
                    type="datetime-local"
                    name="ultima_atualizacao"
                    placeholder="última atualização"
                    title="Data da última atualização dos dados com a Receita Federal"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={(dados.ultima_atualizacao && retornaDataHora(dados.ultima_atualizacao)) || retornaDataHora(moment())}
                />
                <Input
                    text="e-mail da Empresa"
                    type="email"
                    name="email"
                    placeholder="e-mail da empresa"
                    title="Digite o e-mail da empresa"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.email || ""}
                />
            </div>
            <div>
                <Select
                    name="ramoAtividade"
                    text="Selecione o ramo de atividade"
                    options={EnumRamoAtividade}
                    handleOnChange={handleChange}
                    value={dados.ramoAtividade || "Industria"}
                    isEnum={true}
                />
                <Select
                    name="tipo"
                    text="Selecione Matriz ou Filial"
                    options={EnumTipo}
                    handleOnChange={handleChange}
                    value={dados.tipo || "MATRIZ"}
                    isEnum={true}
                />
                <Checkbox
                    title="Quando marcado vincula a empresa a FILIAL"
                    text="Possui Filial"
                    name="possuiFilial"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.possuiFilial}
                    checked={dados.possuiFilial === true ? "checked" : ""}
                />
                <SelectEmpresa
                    name="matriz"
                    text="Selecione Matriz"
                    options={OptionsMatriz}
                    handleOnChange={handleChange}
                    value={dados.matriz || dados.id}
                    isEnum={false}
                />
            </div>
            <div>
                <Input
                    text="CEP"
                    type="text"
                    name="cep"
                    maxlength={10}
                    placeholder="CEP da empresa"
                    title="Digite o CEP da empresa"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.cep ? maskCEP(dados.cep) : ""}
                />
                <Input
                    text="Latitude"
                    type="text"
                    name="latitude"
                    maxlength={10}
                    placeholder="Latitude da empresa"
                    title="Digite a latitude da localização da empresa"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.latitude || ""}
                />
                <Input
                    text="Longitude"
                    type="text"
                    name="longitude"
                    maxlength={10}
                    placeholder="Longitude empresa"
                    title="Digite a Longitude da localização da empresa"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.longitude || ""}
                />
            </div>
            <div>
                <Select
                    text="Selecione o Estado"
                    name="ufId"
                    options={ufs}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.ufId || ""}
                    isEnum={false}
                />
                <Select
                    text="Município"
                    name="municipioId"
                    options={Optionsmunicipios}
                    title="Escolha a município da empresa"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.municipioId || ""}
                    isEnum={false}
                />
            </div>
            <div>
                <Input
                    text="Bairro"
                    type="text"
                    name="bairro"
                    placeholder="Bairro da empresa"
                    title="Digite o bairro da empresa"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    value={dados.bairro || ""}
                />
                <Input
                    text="Logradouro"
                    type="text"
                    name="logradouro"
                    placeholder="Logradouro da empresa"
                    title="Digite o logradouro da empresa"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    value={dados.logradouro || ""}
                />
                <Input
                    text="Número"
                    type="text"
                    name="numero"
                    placeholder="Número da empresa"
                    title="Digite o número da empresa"
                    handleOnChange={handleChange}
                    value={dados.numero || ""}
                />
                <Input
                    text="Complemento"
                    type="text"
                    name="complemento"
                    placeholder="Complemento da empresa"
                    title="Digite o complemento da empresa"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    value={dados.complemento || ""}
                />
            </div>
            <div>
                <Input
                    text="telefone"
                    type="text"
                    name="telefone"
                    placeholder="Telefone"
                    title="Telefone"
                    handleOnChange={handleChange}
                    value={dados.telefone || ""}
                />
                {/* <Input
                    text="última atualização"
                    type="datetime-local"
                    name="ultima_atualizacao"
                    placeholder="última atualização"
                    title="Data da última atualização dos dados com a Receita Federal"
                    handleOnChange={handleChange}
                    value={
                        (empresa.ultima_atualizacao &&
                            retornaDataHora(empresa.ultima_atualizacao)) ||
                        retornaDataHora(moment())
                    }
                /> */}
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}

export default Form;
